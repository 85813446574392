import React, { useEffect, useState } from "react";
import Particles from 'react-tsparticles';
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import "../index.css";

import { ReactComponent as GithubIcon } from "../assets/github.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as KeybaseIcon } from "../assets/keybase.svg";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 920px;
  max-height: 930px;
`;

const AnimatedContactButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100px;
  height: 50px;
`;

const ContactText = styled(motion.h3)`
  z-index: 1;
  user-select: none;
  cursor: pointer;
`;

const LeftButton = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(255, 0, 131);
  box-shadow: rgba(255, 0, 131, 0.7) 0px 10px 40px -10px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;

const RightButton = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(255, 0, 131);
  box-shadow: rgba(255, 0, 131, 0.7) 0px 10px 40px -10px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;

const PortfolioSection = styled.div`
  display: flex;
  height: 1400px;
  background-color: white;
  flex: 1;
`

const particlesOptions = {
  background: {
    color: "#000"
  },
  detectRetina: false,
  fpsLimit: 30,
  interactivity: {
    detectsOn: "canvas",
    events: {
      resize: true
    }
  },
  particles: {
    color: {
      value: "#fff"
    },
    number: {
      density: {
        enable: true,
        area: 1080
      },
      limit: 0,
      value: 300
    },
    move: {
      enable: true,
      random: true,
      animation: {
        enable: true,
        speed: 0.5
      }
    },
    opacity: {
      animation: {
        enable: true,
        minimumValue: 0.05,
        speed: 1,
        sync: false
      },
      random: {
        enable: true,
        minimumValue: 0.05,
        direction: "random"
      },
      value: 1
    },
    shape: {
      type: "circle"
    },
    size: {
      random: {
        enable: true,
        minimumValue: 0.5
      },
      value: 1.5
    }
  }
};

const IndicatorSvg = (props) => {
  return (
    <motion.svg width="15" height="15" position="relative">
      <circle cx="50%" cy="50%" r="5" fill={props.color} />
    </motion.svg>
  )
}

const MailIconSvg = () => (
  <motion.svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="envelope"
    class="svg-inline--fa fa-envelope fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
    ></path>
  </motion.svg>
);

const TwitterIconSvg = () => {
  return (
    <motion.svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="twitter"
      class="svg-inline--fa fa-twitter fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
      ></path>
    </motion.svg>
  );
};

function Home() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="page">
      <div className="main-container">
        <Particles className="particles" options={particlesOptions} />
        <div className="main">
          <h1>RAMIN</h1>
          <h2>DEVELOPER · NEW YORK CITY</h2>
          {/* <motion.h3 style={{ marginTop: "-8px", marginBottom: "13px", color: "white" }} whileHover={{ scale: 1.25 }}>
            <Link to="/portfolio">
              ➡ Portfolio
            </Link>
          </motion.h3> */}
          <Spotify />
        </div>
        <Socials>
          <SocialItem icon={<GithubIcon />} link="https://www.github.com/rao" />
          <SocialItem
            icon={<InstagramIcon />}
            link="https://www.instagram.com/ramin.rai"
          />
          <SocialItem icon={<KeybaseIcon />} link="https://www.keybase.io/krom" />
        </Socials>
        <AnimatedContactButtons onClick={() => setExpanded(!expanded)}>
          <ContactText
            animate={{ opacity: expanded ? 0 : 1, y: expanded ? 30 : 0 }}
          >
            Contact
          </ContactText>
          <LeftButton
            animate={{
              x: expanded ? -35 : 0,
              borderTopRightRadius: expanded ? "50%" : "0%",
              borderBottomRightRadius: expanded ? "50%" : "0%",
              borderBottomLeftRadius: expanded ? "50%" : "10%",
              borderTopLeftRadius: expanded ? "50%" : "10%",
            }}
            onClick={() =>
              window.open("https://twitter.com/messages/compose?recipient_id=4563009502", "_blank")
            }
          >
            <motion.div
              animate={{ opacity: expanded ? 1 : 0, scale: expanded ? 1 : 0 }}
            >
              <TwitterIconSvg />
            </motion.div>
          </LeftButton>
          <RightButton
            animate={{
              x: expanded ? 35 : 0,
              borderTopRightRadius: expanded ? "50%" : "10%",
              borderBottomRightRadius: expanded ? "50%" : "10%",
              borderBottomLeftRadius: expanded ? "50%" : "0%",
              borderTopLeftRadius: expanded ? "50%" : "0%",
            }}
          >
            <motion.div
              animate={{ opacity: expanded ? 1 : 0, scale: expanded ? 1 : 0 }}
            >
              <MailIconSvg />
            </motion.div>
          </RightButton>
        </AnimatedContactButtons>
      </div>
      {/* <PortfolioSection>
        <span>Hi</span>
      </PortfolioSection> */}
      <footer className="footer">
        <span>© design & code by Ramin, {new Date().getFullYear()}</span>
      </footer>
    </div>
  );
}

function Spotify() {
  const [loading, setLoading] = useState(true);
  const [listening, setListening] = useState(false);
  const [artist, setArtist] = useState(null);
  const [track, setTrack] = useState(null);

  useEffect(() => {
    const getSpotifyPlaying = async () => {
      const response = await fetch(
        "https://api.raminraihan.com/spotify-info"
      );
      const { title, artist, playing } = await response.json();
      setArtist(artist);
      setTrack(title);
      setListening(playing);
      setLoading(false);
    };

    getSpotifyPlaying();

    setInterval(() => {
      getSpotifyPlaying();
    }, 2000);
  }, []);

  function NotListening() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IndicatorSvg color='red' />
        <span style={{ marginLeft: '5px' }}>Currently not listening to any tracks.</span>
      </div>
    );
  }

  function Listening() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IndicatorSvg color='green' />
        <span style={{ marginLeft: '5px' }}>
          Listening to {track} by {artist}
        </span>
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IndicatorSvg color='#909090' />
          <span style={{ marginLeft: '5px' }}>Loading...</span>
        </div>
      ) : listening ? (
        <Listening track={track} artist={artist} />
      ) : (
        <NotListening />
      )}
    </div>
  );
}

function Socials(props) {
  return (
    <div className="socials">
      <ul className="socials-social">{props.children}</ul>
    </div>
  );
}

function SocialItem(props) {
  return (
    <motion.li className="social-item" whileHover={{ scale: 1.2 }}>
      <a href={props.link} className="icon-button">
        {props.icon}
      </a>
    </motion.li>
  );
}

export default Home;