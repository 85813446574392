import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

import SongInput from '../components/SongInput.js';
import '../index.css'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background: #e3ddf0;
  font-family: 'Inter', sans-serif;
  color: white;
  flex-wrap: wrap;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

const InitialPage = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;

  span {
    width: 600px;
  }
`

const InputSongs = styled(motion.div)`
  width: 500px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
`

const FirstHalf = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

const SecondHalf = styled.div`
  display: flex;
`

export default function SpotifyRec() {
  const [showModal, setShowModal] = useState(false)
  const [firstSong, setFirstSong] = useState({'name': '', 'artist': '', 'id': ''})
  const [secondSong, setSecondSong] = useState({'name': '', 'artist': '', 'id': ''})
  const [thirdSong, setThirdSong] = useState({'name': '', 'artist': '', 'id': ''})
  const [activeInputValue, setActiveInputValue] = useState(null)

  const showModalFn = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    setActiveInputValue(document.activeElement.value)
  })

  return (
    <Content>
      <InitialPage>
        <span>hey, I'm a sophisticated AI trained to give you the best tunes to your ears. put up to as many songs that you love to get started - the more the better, but three is a perfect amount.</span>
        <motion.button className="ios-15" id="get-started-btn" onClick={() => showModalFn()} animate={ showModal ? "clicked" : "notClicked" } variants={getStartedBtnVariants} transition={{ ease: "easeIn" }} whileTap={{ scale: 1 }} whileHover={{ scale: 1.1 }}>
          <div className="wrapper">
            <span>Get Started</span>
            <div className="circle circle-12"></div>
            <div className="circle circle-11"></div>
            <div className="circle circle-10"></div>
            <div className="circle circle-9"></div>
            <div className="circle circle-8"></div>
            <div className="circle circle-7"></div>
            <div className="circle circle-6"></div>
            <div className="circle circle-5"></div>
            <div className="circle circle-4"></div>
            <div className="circle circle-3"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-1"></div>
          </div>
        </motion.button>
        <InputSongs initial={showModal ? { x: 0, display: 'flex' } : { x: 2000, display: 'none' }} animate={showModal ? { x: 0, display: 'flex' } : { x: 2000, display: 'none' }} transition={{ ease: "easeOut" }}>
          start typing in a song title
          <FirstHalf>
            <SongInput value={firstSong.name} activeInputValue={activeInputValue} onChange={(e) => setFirstSong(e)} key={'first_song'}/>
            <SongInput value={secondSong.name} activeInputValue={activeInputValue} onChange={(e) => setSecondSong(e)}/>
          </FirstHalf>
          <SecondHalf>
            <SongInput value={thirdSong.name} activeInputValue={activeInputValue} onChange={(e) => setThirdSong(e)}/>
          </SecondHalf>
        </InputSongs>
        {/* add submit btn, add functionality to run thru model, make shit to present song */}
      </InitialPage>
    </Content>
  )
}

const getStartedBtnVariants = {
  clicked: {
    x: -2000,
    transitionEnd: {
      display: "none"
    }
  },
  notClicked: {
    x: 0
  }
}