import React, { useState } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

import Dot from "../assets/dot.svg";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: white;
  color: #ff7477;
`

const Main = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
`

const InputArea = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 2rem;
  font-size: 25px
`

const InputField = styled.input`
  outline: none;
  border: none;
  background: none;
  border-bottom: 2px solid;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 0.5rem;
  height: 2rem;
  animation: blink .5s step-end infinite alternate;

  @keyframes blink { 50% { border-color: #ff7477; }  }
`

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50rem;
`

const DotImage = styled.img`
  min-width: 15px;
  min-height: 15px;
  width: 25px;
  height: 25px;
  max-width: 50px;
  max-height: 50px;
  padding: 5px;
`

const AnimatedDotImage = styled(motion.img)`
  min-width: 15px;
  min-height: 15px;
  width: 25px;
  height: 25px;
  max-width: 50px;
  max-height: 50px;
  padding: 5px;
`

// const Footer = styled.div`
//   display: flex;
//   position: fixed;
//   bottom: 0;
//   padding: 10px;
//   font-size: 15px;
//   height: 40px;
// `

export default function VisualizeNumber() {
  const [input, setInput] = useState('')

  const renderDots = () => {
    let dotsNumber = parseInt(input)
    let count = dotsNumber, uiItems = [];
    while (count--)
      uiItems.push(
        <AnimatedDotImage src={Dot} initial={{ x: "50%" }} animate={{ x: "100,500,100" }} transition={{ duration: 2 }} />
      )
    return uiItems
  }

  return (
    <Page>
      <Main>
        <InputArea>
          <span>Enter the number you'd like to visualize:</span>
          <InputField type="text" value={input} onChange={e => setInput(e.target.value)}></InputField>
        </InputArea>
        <Display>
          {renderDots()}
        </Display>
        {/* <Footer>
                    <span>Made by Ramin</span>
                </Footer> */}
      </Main>
    </Page>
  )
}

const NumberInput = () => {
  const [number, setNumber] = useState('')

  return (
    <InputField type="text" value={number} onChange={e => setNumber(e.target.value)} />
  )
}