import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BrowserRouter as Router, Link } from 'react-router-dom';

export default function Portfolio() {
    return (
        <div>
            <span>Hi</span>
        </div>
    )
}