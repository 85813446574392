import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const Container = styled.div``

const Input = styled.input`
  width: 200px;
  height: 33px;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 13px;
  text-overflow: ellipsis;
`

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const SearchResultsDropdown = styled.div`
  width: 200px;
  height: 192px;
  margin-top: 5px;
  background: rgb(40, 40, 40);
  position: absolute;
  color: white;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
`

const SearchResultItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  font-size: 12px;
`

const BorderBottom = styled.div`
  border-bottom: solid 1px white;
  position: relative;
  bottom: 1px;
  right: 0;
  width: 80%;
`

const AlbumArt = styled.img`
  height: 45px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
`

const SongDescription = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
  padding: 10px;
  justify-content: space-around;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const ArtistDescription = styled.span`
  color: rgb(116, 116, 116);
  font-size: 10px;
`

const LoadingContainer = styled.div`
  height: 25px;
  width: 200px;
  border-radius: 10px;
  position: absolute;
  background: rgb(40, 40, 40);
  display: flex;
  justify-content: center;
  align-items: center;
`

// TODO: implement search function --> use site as guide: https://dev.to/asimdahall/simple-search-form-in-react-using-hooks-42pg

export default function SongInput(props) {
    const [focused, setFocused] = useState(false)
    const [inputValue, setInputValue] = useState(props.value)
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false)

    const onChangeFn = (e) => {
        props.onChange({'name': e.target.value, 'artist': '', 'id': ''})
        setInputValue(e.target.value)
    }

    const handleSongClick = (item) => {
        setFocused(false)
        setInputValue(item.name)
        props.onChange({'name': item.name, 'artist': item.artist, 'id': item.spotify_id})
    }

    const fetchTracks = async (value) => {
        setLoading(true)
        await fetch(`https://api.raminraihan.com/spotify-search`, {
            headers: { 'Query': value }
        }).then(async (response) => {
            setSearchResults(await response.json())
            setLoading(false)
        })
    }

    useEffect(() => {
        if (props.value === props.activeInputValue) { setFocused(true) } else { setFocused(false) }
        if (!inputValue) return
        fetchTracks(inputValue)
    }, [props, inputValue])

    return (
        <Container>
            <Input type="text" placeholder="ex: The Hills" value={props.value} onChange={onChangeFn} />
            {(focused && loading) ? 
            <LoadingContainer>
                <ReactLoading type={'bubbles'} />
            </LoadingContainer> 
            : 
            ((inputValue) && (focused) && <SearchResultsDropdown>
                {searchResults.map(item => (
                    <SearchResultsContainer onClick={() => handleSongClick(item)}>
                      <SearchResultItem>
                        <AlbumArt src={item.cover_images[2].url} alt={item.name} height="64px" />
                        <SongDescription>
                            <span>{item.name}</span>
                            <ArtistDescription>{item.artist}</ArtistDescription>
                        </SongDescription>
                      </SearchResultItem>
                      {(item.spotify_id === searchResults[2].spotify_id ? undefined : <BorderBottom />)}
                    </SearchResultsContainer>
                ))}
            </SearchResultsDropdown>)}
        </Container>
    )
}