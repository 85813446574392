import React from 'react';
import { 
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import Home from './pages/Home.js'
import VisualizeNumber from './pages/VisualizeNumber.js'
import Portfolio from './pages/Portfolio.js'
import SpotifyRec from './pages/SpotifyRec'

export default function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/visualize-number" component={VisualizeNumber}/>
                <Route path="/portfolio" component={Portfolio}/>
                <Route path="/spotify-rec" component={SpotifyRec}/>
            </Switch>
        </Router>
    )
}